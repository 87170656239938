import { Table, TableBody, TableData, TableHead, TableHeader, TableRow } from 'boss-ui';
import { Heading2 } from './headings';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    min-width: 800px;
`;

export function FormContainer({ title, children }) {
  return (
    <Container>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>
              <Heading2>Feature Flag - {title}</Heading2>
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableData>{children}</TableData>
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  );
}