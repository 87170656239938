import { Loader } from 'boss-ui';
import { Box } from 'reflexbox';
import React, { useEffect, useMemo, useState } from 'react';
import { Heading3 } from './headings';
import { FEATURE_FLAG_ID_RESOURCE_ALLOCATION_V2, useGetResourceAllocationV2Flag, useListAllEvents } from 'boss-ui/src/FeatureFlagApiClient';
import { FormContainer } from './FormContainer';
import { useEventIdList } from './hooks';
import { FormSectionSaveChanges, FormSectionWhitelistEvents, StyledForm } from './FormComponents';
import Api from '../../api';

const API = new Api();


function FormSectionGloballyEnabled({ globallyEnabled, setGloballyEnabled }) {
  return (<section>
    <header>
      <Heading3>Enable Globally?</Heading3>
    </header>
    <Box>
      <input type="checkbox" checked={globallyEnabled} onChange={(e) => {
        setGloballyEnabled(e.target.checked);
      }} />
      {globallyEnabled ? 'Enabled for all events' : 'Only enabled for events specified below'}
    </Box>
  </section>);
}

export function ResourceAllocationV2Form() {
  const [globallyEnabled, setGloballyEnabled] = useState(false);
  const { loading: loadingEvents, events } = useListAllEvents(API);
  const { loading: loadingFlag, flagConfig } = useGetResourceAllocationV2Flag(API);
  const loading = loadingFlag || loadingEvents;

  const {
    enabledEvents,
    eventsToChooseFrom,
    enabledEventIds,
    setEnabledEventIds,
    addEventId,
    removeEventId
  } = useEventIdList({ events });

  const updatedConfig = useMemo(() => ({
    isGloballyEnabled: {
      enabled: globallyEnabled
    },
    enabledGroupIds: enabledEventIds
  }), [globallyEnabled, enabledEventIds]);

  useEffect(() => {
    setGloballyEnabled(flagConfig?.isGloballyEnabled?.enabled ?? false);
    setEnabledEventIds(flagConfig?.enabledGroupIds ?? []);
  }, [flagConfig, setEnabledEventIds, setGloballyEnabled]);

  function FormContent() {
    return <StyledForm>
      <section>
        <header>
          <Heading3>About</Heading3>
        </header>
        <p>This feature flag configures use of version 2 of Resource Allocation logic</p>
      </section>
      <FormSectionGloballyEnabled globallyEnabled={globallyEnabled} setGloballyEnabled={setGloballyEnabled} />
      <FormSectionWhitelistEvents addEvent={addEventId} removeEvent={removeEventId}
                                  eventsToChooseFrom={eventsToChooseFrom} enabledEvents={enabledEvents} />
      <FormSectionSaveChanges api={API} featureFlagId={FEATURE_FLAG_ID_RESOURCE_ALLOCATION_V2} configPayload={updatedConfig} />
    </StyledForm>;
  }

  return (
    <FormContainer title="Resource Allocation V2">
      <Loader loading={loading}>
        <FormContent />
      </Loader>
    </FormContainer>
  );
}
