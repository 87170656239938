import Api from '../api';

/**
 * Creates a polling mechanism to repeatedly fetch data from a given URL until a condition is met or a timeout occurs.
 *
 * @param {string} url - The URL to fetch data from.
 * @param {number} [interval=10000] - The interval in milliseconds between each poll.
 * @param {number} [timeout=100000] - The maximum time in milliseconds to keep polling before timing out.
 * @param {function} [checkFun=(res) => res.status === 200] - A function to check if the polling condition is met. It receives the response data and should return a boolean.
 * @returns {Object} - An object with a `stop` method to stop the polling and a `promise` that resolves when the polling condition is met or rejects if an error occurs or the timeout is reached.
 */

const API = new Api();
export const createPolling = (path, endpoint, {interval = 10000, timeout = 100000, checkFun = (res) => res.status === 200} = {}) => {
    const startTime = Date.now();
    let timeoutId;

    const promise = new Promise((resolve, reject) => {
        const executePolling = async () => {
            try {
                // Check if timeout has been reached before making the request
                if (Date.now() - startTime > timeout) {
                    return reject(new Error('Polling timed out'));
                }

                const response = await API.get(path, endpoint);
                if (!response) {
                    throw new Error(`Polling error!`);
                }
                const data = response;

                // Check if the polling condition is met
                if (checkFun(data)) {
                    return resolve(data);
                }

                // Schedule the next polling
                timeoutId = setTimeout(executePolling, interval);
            } catch (error) {
                console.error('Polling error:', error);
                return reject(error);
            }
        };

        executePolling();
    });

    return {
        promise,
        stop: () => clearTimeout(timeoutId)
    };
};