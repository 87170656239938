import { useTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import React from 'react';

export function LoaderSkeleton({height='300px'}={}) {
  const theme = useTheme();
  return <SkeletonTheme
    color={theme?.color?.skeleton?.color}
    highlightColor={theme?.color?.skeleton?.highlightColor}
  >
    <Skeleton height={height} />
  </SkeletonTheme>;
}

export function Loader({loading, children}) {
  return loading ? <LoaderSkeleton/> : children;
}
