import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';

export function useEventIdList({ events, displayEventsMaxAgeDays = 90 } = {}) {
  const [enabledEventIds, setEnabledEventIds] = useState([]);

  const enabledEventIdsSet = useMemo(() => new Set(enabledEventIds), [enabledEventIds]);
  const enabledEvents = useMemo(() => {
    return events.filter(event => enabledEventIdsSet.has(event.eventId));
  }, [events, enabledEventIdsSet]);

  const eventEarliestEndDate = useMemo(() => moment().utc().subtract(displayEventsMaxAgeDays, 'days'), [displayEventsMaxAgeDays]);
  useEffect(() => {
    console.log(`Only displaying events with endDate >= ${eventEarliestEndDate}`);
  }, [eventEarliestEndDate]);
  const filterRecentOrFutureEvents = useCallback((event) => moment(event.endDate) >= eventEarliestEndDate, [eventEarliestEndDate]);

  const eventsToChooseFrom = useMemo(() => {
    return events.filter(event => !enabledEventIdsSet.has(event.eventId) && filterRecentOrFutureEvents(event));
  }, [events, enabledEventIdsSet, filterRecentOrFutureEvents]);

  function addEventId(eventId) {
    if (!enabledEventIds.includes(eventId)) {
      setEnabledEventIds([...enabledEventIds, eventId]);
    }
  }

  function removeEventId(eventId) {
    setEnabledEventIds(enabledEventIds.filter((id) => id !== eventId));
  }

  return { enabledEvents, eventsToChooseFrom, enabledEventIds, setEnabledEventIds, addEventId, removeEventId };
}