import React from 'react';
import { Menu } from 'boss-ui';
import { Flex } from 'reflexbox';
import { ResourceAllocationV2Form } from './ResourceAllocationFeatureFlagForm';
import { HideUserScoringFeatureFlagForm } from './HideUserScoringFeatureFlagForm';
import { useAppContext } from '../../libs/context-lib';
import { filterMenuByRole } from '../../libs/user-lib';

import styled from 'styled-components';

const FormsContainer = styled(Flex)`
    gap: 30px;
`;

export default function FeatureFlagsContainer() {
  const { state } = useAppContext();

  return (
    <Flex flexDirection="column">
      <Menu menuItems={filterMenuByRole(state.user)} />
      <Flex width={1} justifyContent="center">
        <FormsContainer mt="30px" mb="5%" flexDirection="column">
          <ResourceAllocationV2Form />
          <HideUserScoringFeatureFlagForm />
        </FormsContainer>
      </Flex>
    </Flex>
  );
}