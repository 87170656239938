import { Loader } from 'boss-ui';
import React, { useEffect, useMemo } from 'react';
import { Heading3 } from './headings';
import { FEATURE_FLAG_ID_HIDE_USER_SCORING, useGetFeatureFlag, useListAllEvents } from 'boss-ui/src/FeatureFlagApiClient';
import { FormContainer } from './FormContainer';
import { useEventIdList } from './hooks';
import { FormSectionSaveChanges, FormSectionWhitelistEvents, StyledForm } from './FormComponents';
import Api from '../../api';

const API = new Api();

export function HideUserScoringFeatureFlagForm() {
  const { loading: loadingEvents, events } = useListAllEvents(API);
  const {
    loading: loadingFlag,
    flagConfig
  } = useGetFeatureFlag(API, { featureFlagId: FEATURE_FLAG_ID_HIDE_USER_SCORING });
  const loading = loadingFlag || loadingEvents;

  const {
    enabledEvents,
    eventsToChooseFrom,
    enabledEventIds,
    setEnabledEventIds,
    addEventId,
    removeEventId
  } = useEventIdList({ events });

  const updatedConfig = useMemo(() => ({
    hiddenForEventIds: enabledEventIds
  }), [enabledEventIds]);

  useEffect(() => {
    setEnabledEventIds(flagConfig?.hiddenForEventIds ?? []);
  }, [flagConfig, setEnabledEventIds]);

  function FormContent() {
    return <StyledForm>
      <section>
        <header>
          <Heading3>About</Heading3>
        </header>
        <p>This feature flag configures hiding scoring tab and scoring info from users for specified events.</p>
      </section>
      <FormSectionWhitelistEvents addEvent={addEventId} removeEvent={removeEventId}
                                  eventsToChooseFrom={eventsToChooseFrom} enabledEvents={enabledEvents} />
      <FormSectionSaveChanges api={API} featureFlagId={FEATURE_FLAG_ID_HIDE_USER_SCORING} configPayload={updatedConfig} />
    </StyledForm>;
  }

  return (
    <FormContainer title="Hide User Scoring">
      <Loader loading={loading}>
        <FormContent />
      </Loader>
    </FormContainer>
  );
}
