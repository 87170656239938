import React, { useEffect, useState } from 'react';
import { postFeatureFlag } from 'boss-ui/src/FeatureFlagApiClient';
import { toast } from 'react-toastify';
import { StyledButton } from './StyledButton';
import styled from 'styled-components';
import { Form, TrashCanIcon } from 'boss-ui';
import { HorizontalLayout } from './HorizontalLayout';
import moment from 'moment/moment';
import { Heading3, Heading4 } from './headings';


export const StyledForm = styled(Form)`
    max-width: 1200px;
    padding: 20px;
    border: none;

    & section:not(:first-child) {
        margin-top: 30px;
    }
`;
export const TitleParagraph = styled.p`
    font-weight: bold;
    margin-bottom: 0;
`;
export const SubtitleParagraph = styled.p`
    margin-top: 0;
`;

export const EventsDropdown = styled.select`
    flex-grow: 1;
    min-width: 200px;
    height: 30px;
`;

export function EventRecord({ event, removeEvent, ...props }) {
  return (<HorizontalLayout {...props}>
    <div>
      <TitleParagraph>{event.name} ({event.eventId})</TitleParagraph>
      <SubtitleParagraph>End Date: {moment(event.endDate).fromNow()}</SubtitleParagraph>
    </div>
    <RemoveButton onClick={() => removeEvent(event.eventId)}>Remove</RemoveButton>
  </HorizontalLayout>);
}

export function AddRecordContainer({ events, addEvent }) {
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const selectOnChange = (e) => {
    setSelectedEvent(e.target.value);
  };
  const buttonOnClick = () => {
    addEvent(selectedEvent);
  };

  useEffect(() => {
    const firstEvent = events.length > 0 ? events[0].eventId : undefined;
    setSelectedEvent(firstEvent);
  }, [events]);

  return <HorizontalLayout>
    {events.length > 0 && <>
      <EventsDropdown value={selectedEvent} onChange={selectOnChange}>
        {events.map((event) => <option key={event.eventId}
                                       value={event.eventId}>{event.name} ({event.eventId})</option>)}
      </EventsDropdown>
      <StyledButton type="button" onClick={buttonOnClick}>Add Event</StyledButton>
    </>}
  </HorizontalLayout>;

}

export function RemoveButton({ ...props }) {
  return <StyledButton type="button" {...props}><TrashCanIcon /></StyledButton>;
}

export function FormSectionSaveChanges({ api, configPayload, featureFlagId }) {
  const [savingChanges, setSavingChanges] = useState(false);

  async function submitForm() {
    setSavingChanges(true);
    try {
      await postFeatureFlag(api, { featureFlagId, configPayload });
      toast('Successfully updated feature flag');
    } catch (error) {
      console.error(error);
    } finally {
      setSavingChanges(false);
    }
  }

  return <section>
    <StyledButton disabled={savingChanges} type="button" onClick={submitForm}>
      {savingChanges ? 'Saving Changes...' : 'Save Changes'}
    </StyledButton>
  </section>;
}

export function FormSectionWhitelistEvents({ enabledEvents, eventsToChooseFrom, addEvent, removeEvent }) {
  return (<section>
    <header>
      <Heading3>Enabled for Specific Events</Heading3>
    </header>
    {
      enabledEvents.map((event) => (
        <EventRecord key={event.eventId} event={event} removeEvent={removeEvent} />
      ))
    }
    {eventsToChooseFrom.length > 0 && <>
      <Heading4>Add another event to whitelist (current & future events)</Heading4>
      <AddRecordContainer events={eventsToChooseFrom} addEvent={addEvent} />
    </>
    }
  </section>);
}