import React, { useState, useEffect } from 'react';
import { Flex, Box } from 'reflexbox';
import { Form, RegularText, Button, SpinnerIcon, TableHead, TableRow, TableHeader, TableBody, TableData, Select, TableItemSkeleton, Table } from 'boss-ui';
import { useEventContext } from '../../libs/context-lib';
import { toast } from 'react-toastify';
import { createPolling } from '../../libs/polling-lib';
import Api from '../../api';
import { onError } from '../../libs/error-lib';

const NotificationTabOptions = [{
    value: 'Notification Center',
    label: 'Notification Center'
},
{
    value: 'Notification Logs',
    label: 'Notification Logs'
}
];

const API = new Api();
const Admin = () => {
    const SEND_EMAIL_POST_EVENT = "Score emails can only be sent after the completion of the event";
    const SENDING_EMAILS_NOTIFICATION = "Initiated sending scores, it will take several seconds until all the emails are sent...";
    const { state } = useEventContext();
    const event = state.event;
    const [activityProgress, setActivityProgress] = useState({});
    const eventId = event.eventId;
    const [selectedTab, setSelectedTab] = useState(NotificationTabOptions[0]);
    const [isLoading, setIsLoading] = useState(false);
    const [activityLogs, setActivityLogs] = useState([]);

    useEffect(() => {

        async function fetchActivityLogs() {
            await fetchNotificationLogs();
        };

        fetchActivityLogs();

    }, [selectedTab]); // Fetch logs when tab changes

    const fetchNotificationLogs = async () => {
        if (selectedTab.value === 'Notification Logs') {
            setIsLoading(true);
            try {
                const response = await API.get('events', `/admin/events/${eventId}/notification-activity`);
                response.activities.sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));
                setActivityLogs(response.activities);
            } catch (err) {
                onError(err);
                toast.error('Failed to fetch notification logs');
            } finally {
                setIsLoading(false);
            }
        }
    }

    const handleTabChange = async (tab) => {
        setSelectedTab(tab);
    }

    const isEventEnded = () => {
        return new Date(event.endDate) < new Date();
    }

    const sendScoresTrigger = async () => {
        try {
            setActivityProgress(prev => ({ ...prev, EMAIL_SCORE_ACTIVITY: true }));
            const eventBody = { eventId, type: 'EMAIL_SCORE_ACTIVITY' };
            const initialResponse = await API.post('events', `/admin/events/${eventId}/notification-activity`, { body: eventBody });
            if (initialResponse) {
                const processId = initialResponse.processId;
                const evalFunction = (res) => {
                    return res.activity.status === 'COMPLETED' || res.activity.status === 'FAILED';
                };
                const polling = createPolling('events', `/admin/events/${eventId}/notification-activity/${processId}`, {interval : 5000, timeout : 400000, checkFun : evalFunction});
                polling.promise.then((res) => {
                    toast('Scores sent successfully');
                    setActivityProgress(prev => ({ ...prev, EMAIL_SCORE_ACTIVITY: false }));
                }).catch((err) => {
                    console.log("Error while sending scores", err);
                    toast('Error while sending scores');
                    setActivityProgress(prev => ({ ...prev, EMAIL_SCORE_ACTIVITY: false }));
                });
            }
        } catch (e) {
            setActivityProgress(prev => ({ ...prev, EMAIL_SCORE_ACTIVITY: false }));
            onError(e);
        }
    };

    const sendScores = async () => {
        if (isEventEnded()) {
            toast(SENDING_EMAILS_NOTIFICATION);
            await sendScoresTrigger();
        } else {
            toast(SEND_EMAIL_POST_EVENT);
        }
    };

    const activityNames = [{
        activityId: "EMAIL_SCORE_ACTIVITY",
        label: "Send Score Mails",
        description: "Send score mails to all participants",
        action: sendScores
    }];

    const NotificationActivityTab = () => {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>
                            <RegularText fontSize="14px" >Activity</RegularText>
                        </TableHeader>
                        <TableHeader>
                            <RegularText fontSize="14px" >Description</RegularText>
                        </TableHeader>
                        <TableHeader>
                            <RegularText fontSize="14px" >Status</RegularText>
                        </TableHeader>
                    </TableRow>
                </TableHead>


                <TableBody>
                    {activityNames.map((activity, index) => (

                        <TableRow key={index}>
                            <TableData>
                                <Button onClick={activity.action} disabled={activityProgress[activity.activityId]}>
                                    {activity.label}
                                </Button>
                            </TableData>
                            <TableData>
                                <RegularText RegularText fontSize="11px" ml="22px">
                                    {activity.description}
                                </RegularText>
                            </TableData>
                            <TableData>
                                {activityProgress[activity.activityId] ? (
                                    <Box>
                                        <SpinnerIcon size="18px" />
                                        <RegularText fontSize="12px" ml="8px">{`In Progress`}</RegularText>
                                    </Box>
                                ) : (
                                    <RegularText fontSize="12px" ml="8px">Idle</RegularText>
                                )}

                            </TableData>
                        </TableRow>
                    )
                    )}


                </TableBody>
            </Table>
        );
    }

    const NotificationActivityLogsTab = () => {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>
                            <RegularText>Activity Name</RegularText>
                        </TableHeader>
                        <TableHeader>
                            <RegularText>Activity Status</RegularText>
                        </TableHeader>
                        <TableHeader>
                            <RegularText>Ran At</RegularText>
                        </TableHeader>
                        <TableHeader>
                            <RegularText>Process Id</RegularText>
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? <TableItemSkeleton numberOfCells={4} /> :

                        (
                            activityLogs.map((activity, index) => (
                                <TableRow key={index}>
                                    <TableData>
                                        <RegularText>{activity.type}</RegularText>
                                    </TableData>
                                    <TableData>
                                        <RegularText>{activity.status}</RegularText>
                                    </TableData>
                                    <TableData>
                                        <RegularText>{new Date(activity.createdOn).toUTCString()}</RegularText>
                                    </TableData>
                                    <TableData>
                                        <RegularText>{activity.processId}</RegularText>
                                    </TableData>
                                </TableRow>
                            ))
                        )


                    }
                </TableBody>
            </Table>
        );
    }

    const RenderTabContent = () => {
        switch (selectedTab) {
            case NotificationTabOptions[0]:
                return <NotificationActivityTab />;
            case NotificationTabOptions[1]:
                return <NotificationActivityLogsTab />;
            default:
                return <NotificationActivityTab />;
        }
    }



    return (
        <Flex mb="5%" mt="60px" justifyContent="center">
            <Form noBorder>
                <Box px="60px" py="40px" width="924px">
                    <Flex p="40px" pb="30px" flexDirection="column">
                        <Flex justifyContent="center">
                            <RegularText mediumWeight>{selectedTab.value}</RegularText>
                        </Flex>
                        <Flex width={1}>
                            <Box width={1 / 2}>
                                <Flex justifyContent="center" alignItems="center" width="240px" mt="20px" mb="20px">
                                    <Select options={NotificationTabOptions} value={selectedTab} onChange={handleTabChange} />
                                </Flex>
                            </Box>
                        </Flex>
                        <Flex mt="20px" alignItems="center" width="100%">
                            <RenderTabContent />
                        </Flex>
                    </Flex>
                </Box>
            </Form>
        </Flex>
    );
}

export default Admin;
